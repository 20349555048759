:root {
  --Peach: #fbece6;
  --Navy-Blue: #263154;
  --Primary: #60034c;
  --Secondary: #e58b76;
  --Error: #ff0000;
  --White: #ffffff;
  --Black: #242424;
  --Grey: #666666;
  --Border: #d3d3d3;
  --Bgprimary: #efe6ed;
  --Bgprimaryl: #FFFAF9;
  --Bgsecondary: #fbece6;
  --Bggrey: #f5f5f5;
  --Blue: #54C1FF;
}

* {
  box-sizing: border-box;
}

html {
  background-color: #fafafa;
}

a {
  cursor: pointer;
  font-family: "Overpass", sans-serif;
  color: var(--Secondary);
}
a:hover, a:visited, a:focus {
  color: var(--Secondary);
}
.graycon {
  color: var(--Grey);
  line-height: 30px;
}

.fluidimg {
  width: 100%;
  height: auto;
}
.wfull{
  width: 100%;
}

body {
  font-family: "Overpass", sans-serif;
  font-weight: 400;
  max-width: 100%;
  margin: 0 auto;
  background: #fff;
  overflow: auto;
}

textarea {
  font-family: "Overpass", sans-serif;
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.twocol>div {
  width: 50%;
}

.bgsecondary {
  background: var(--Bgsecondary);
}

.bgprimary {
  background: var(--Bgprimary);
}
.herosection {
  background: var(--Bgprimaryl);
}

.herosection h1 {
  color: var(--Primary);
}

.herosection .context p.bannerhigh {
  color: var(--Secondary);
}

.c-secondary {
  color: var(--Secondary);
}

.dashed {
  stroke-dasharray: 6;
}

.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 100px;
}

.image svg {
  max-width: 100%;
}

.afteranimation {
  animation-name: showblk;
  animation-duration: 2s;
  animation-delay: 4s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.animateline {
  stroke-dasharray: 2338.5;
  stroke-dashoffset: 2338.5;
  animation: dash2 6s linear forwards;
}

.sgroup {
  transform: translateX(-29px);
  animation-name: toright;
  animation-duration: 2.5s;
  animation-delay: 0.75s;
  opacity: 0;
  animation-fill-mode: forwards;
}

.dashline {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation-name: dash1;
  animation-duration: 3s;
  animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

.graphline {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash3 6s linear forwards;
}

.c-primary {
  color: var(--Primary);
}

.ai-link {
  text-decoration: none;
  color: var(--Secondary);
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.ai-btn {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  border: none;
  line-height: 20px;
  border-radius: 4px;
  font-weight: 500;
  color: var(--White);
  width: fit-content;
  cursor: pointer;
  box-sizing: border-box;
  align-self: center;
  border: 1px solid;
  white-space: nowrap;
}

.ai-btn.round {
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.ai-btn.round.primary {
  background: var(--Primary);
  border-color: var(--Primary);
  color: var(--White);
}

.ai-btn.round.secondary {
  background: var(--Secondary);
  border-color: var(--Secondary);
  color: var(--White);
}

.ai-btn.round.small {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
  box-sizing: border-box;
  font-size: 20px;
}

.ai-btn.small {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 14px;
}

.ai-btn.small img {
  max-width: 14px;
}

.ai-btn.ex-small {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 12px;
}

.ai-btn.ex-small img {
  max-width: 12px;
}

.ai-btn.line {
  background: none;
}

.ai-btn.line.primary {
  border-color: var(--Primary);
  color: var(--Primary);
}

.ai-btn.line.secondary {
  border-color: var(--Secondary);
  color: var(--Secondary);
}

.ai-btn.plain {
  border: none;
  padding: 0;
  background: none;
}

.ai-btn.solid.primary {
  background: var(--Primary);
  border-color: var(--Primary);
  color: var(--White);
}

.ai-btn.solid.secondary {
  background: var(--Secondary);
  border-color: var(--Secondary);
  color: var(--White);
}

.ai-btn.big {
  padding: 12px 16px;
}

.ai-btn img,
.ai-btn svg {
  display: inline-block;
  vertical-align: middle;
  max-height: 16px;
}

.minh600 {
  min-height: 400px;
}

@keyframes toright {
  0% {
    opacity: 1;
    transform: translateX(-29px);
  }

  50% {
    opacity: 1;
    transform: translateX(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(10px);
  }
}

@keyframes dash1 {
  from {
    stroke-dashoffset: 200;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash3 {
  0% {
    stroke-dashoffset: 1000;
  }

  30% {
    stroke-dashoffset: 650;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash2 {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes showblk {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.sectionhead {
  padding: 40px 0;
  background-color: var(--Primary);
  color: var(--White);
  text-align: center;
  background-image: url(/..//app/Assets/line_bg_l.svg);
  background-position: center center;
  background-size: calc(100% - 200px);
  background-repeat: repeat-y;
}

.sectionhead h1 {
  margin: 0;
  font-size: 36px;
  line-height: 48px;
  color: var(--White);
  text-align: center;
}

.checkcon {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkcon input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px var(--Grey) solid;
  box-sizing: border-box;
  border-radius: 4px;
}

.checkcon:hover input~.checkmark {
  background-color: var();
}

.checkcon input:checked~.checkmark {
  background-color: var(--Primary);
  border: 1px var(--Primary) solid;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkcon input:checked~.checkmark:after {
  display: block;
}

.checkcon .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-mui {
  padding: 0;
  background: #f1f1f1;
  width: 100%;
}

.input-mui .MuiSelect-select {
  font-size: 14px;
  border: none !important;
  padding: 8px 32px 8px 16px !important;
}

.input-mui fieldset {
  border: none !important;
}

.input-mui-lg {
  padding: 0;
  background: #f1f1f1;
  width: 100%;
  margin-top: 10px;
}

.input-mui-lg .MuiSelect-select {
  font-size: 14px;
  line-height: 23px;
  border: none !important;
  padding: 12px 32px 12px 16px !important;
}

.input-mui-lg fieldset {
  border: none !important;
}

.subnavbarmenu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 48px;
  align-items: center;
  height: 100%;
  flex: 1;
  flex-wrap: wrap;
}

.subnavbarmenu .SUBITEM,
.subnavbarmenu .SUBITEM-selected {
  display: block;
  text-align: center;
  color: #555;
  padding: 18px 0;
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  position: relative;
  white-space: nowrap;
  display: flex;
  column-gap: 8px;
  padding: 8px 0;
  cursor: pointer;
}

.subnavbarmenu .SUBITEM:hover,
.subnavbarmenu .SUBITEM-selected:hover {
  color: var(--Primary);
}

.subnavbarmenu .SUBITEM-selected {
  color: var(--Primary);
  border-bottom: 3px solid var(--Primary);
  border-radius: 0px;
  padding: 8px 0 5px;
}

.subnavbarmenu.navtab {
  padding: 16px;
}

.tab-content {
  display: flex;
  width: 100%;
  flex: 1;
}

.tab-content>div {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: var(--White);
}

.tab-content>div.tab-pane {
  display: none;
}

.tab-content>div.active {
  display: flex;
}

.react-tooltip-lite {
  max-width: 300px !important;
  font-size: 14px;
}

.ai-btn[disabled],
.ai-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.table_height {
  overflow-y: auto;
  max-height: calc(10 * 48px);
}

.MuiTable-stickyHeader th {
  color: var(--Primary);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}

.MuiTable-stickyHeader th .MuiIconButton-root {
  padding: 12px 0 12px 10px;
}

.MuiTable-stickyHeader .link {
  color: var(--Primary);
  font-weight: 600;
}

.MuiTable-stickyHeader .c-green {
  color: var(--Green);
}

.MuiTable-stickyHeader .c-orange {
  color: var(--Orange);
}

.MuiTable-stickyHeader .c-error {
  color: var(--Error);
}

.MuiTable-stickyHeader tfoot {
  background: var(--Bgprimary);
}

.MuiTable-stickyHeader tfoot td {
  font-size: 14px;
  font-weight: bold;
  color: var(--Primary);
}

.MuiTable-stickyHeader tfoot td:first-child {
  color: var(--Navy-Blue);
}

.MuiTable-stickyHeader th:nth-child(7),
.MuiTable-stickyHeader th:nth-child(8),
.MuiTable-stickyHeader th:nth-child(9),
.MuiTable-stickyHeader th:nth-child(10) {
    text-align: right;
}

.MuiTable-stickyHeader tbody td:nth-child(7),
.MuiTable-stickyHeader tbody td:nth-child(8),
.MuiTable-stickyHeader tbody td:nth-child(9),
.MuiTable-stickyHeader tbody td:nth-child(10) {
    text-align: right;
}

.MuiTable-stickyHeader tfoot td {
  position: sticky;
  bottom: 0;
  background: var(--Bgprimary);
}

.MuiTable-stickyHeader tfoot td:nth-child(7),
.MuiTable-stickyHeader tfoot td:nth-child(8) {
    text-align: right;
}

.userform {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.maindiv {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.maindiv>div {
  width: 100%;
}

.sectiondivide {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 128px;
  padding: 100px 200px;
  overflow: hidden;
}

.sectiondivide.jcenter {
  justify-content: center;
}

.sectiondivide.sectioncol {
  flex-direction: column;
  row-gap: 16px;
  padding-top: 50px;
}

.sectiondivide.sectioncol>div {
  max-width: 100%;
  width: 100%;
}

.sectiondivide hr {
  width: 100%;
  background: #979797;
  margin: 0;
  opacity: 0.3;
}

.endsectiondivide {
  background: #f4f4f4;
}

.advisorsectiondivide {
  background: var(--Peach);
}

.button {
  width: 155px;
  height: 50px;
  line-height: 36px !important;
  text-decoration: none;
  text-align: center;
  background-color: var(--Primary);
  color: white !important;
  padding: 8px 16px !important;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  display: inline-block;
}

ul.navbarmenu>li a.button.secondary,
.button.secondary {
  background: var(--Secondary);
}

ul.navbarmenu>li a.button.secondary:hover,
ul.navbarmenu>li a.button:hover {
  background: var(--Black);
}

.input-type-text-area-sm {
  width: 100%;
  border: 1px solid #999;
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  height: 50px;
  padding: 8px 16px;
  font-size: 14px;
}

.input-type-text-area-lg {
  width: 100%;
  height: 100px;
  background-color: white;
  padding: 8px 16px;
  font-family: "Overpass", sans-serif;
  border: 1px solid #666666;
  border-radius: 5px;
  resize: none;
  font-size: 14px;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup-object {
  position: relative;
  background: var(--White);
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
  width: 460px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
}

.heading {
  background-color: var(--White);
  display: flex;
  align-items: center;
  list-style: none;
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  box-sizing: border-box;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  height: 60px;
  padding-left: 30px;
  font-weight: 700;
  font-size: 20px;
  font-family: Inter;
  color: #3e4968;
  letter-spacing: 0.25px;
}

.heading .icon {
  width: 28px;
  height: 28px;
  margin-bottom: -3px;
  margin-right: 8px;
  box-sizing: "border-box";
}

.alertType {
  display: none;
}

.body-message {
  padding: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  width: 100%;
}

.close-button {
  float: right;
  background-color: black;
  color: #ffffff;
  padding: 6px 10px 6px 10px;
  border: none;
  font-size: 100%;
}

.signupform {
  display: flex;
  justify-content: space-between;
  max-width: 420px;
  column-gap: 16px;
  row-gap: 16px;
  align-items: flex-start;
}

.signupform>form {
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

.pop-up {
  background-color: white;
  border: 2px solid black;
  width: 450px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  position: fixed;
  padding: 8px 8px 32px;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.tickcon {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: -16px;
}

.tickcon .tick {
  display: inline-block;
  width: 64px;
  opacity: 1;
}

.popuptext {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 32px;
  row-gap: 16px;
  margin-top: 24px;
}

.userselection {
  row-gap: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--Black);
}

.userselection h3,
.userselection p {
  margin: 0;
  text-align: center;
}

.main-header>input:before {
  border: 1px solid #e58b76;
  box-sizing: border-box;
}

.invalidMessage {
  font-size: 14px;
  color: #fe0006 !important;
  margin: 0;
}

.divlast {
  display: flex;
  column-gap: 48px;
}

.imageleft img,
.adv-img img,
.image img {
  max-width: 100%;
  height: auto;
}

section::after {
  content: "";
  display: table;
  clear: both;
}

.sectiondivide.demoscreen {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 128px;
  padding: 100px 200px;
  overflow: hidden;
}

.inputsection {
  width: 100%;
}

.context {
  row-gap: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.boxcont p,
.context p {
  font-size: 16px;
  color: #666;
  margin: 0;
  line-height: 26px;
}

.context p.bannerhigh {
  font-size: 13px;
  color: #999;
  font-weight: 700;
  text-transform: uppercase;
  word-spacing: 1px;
}

.context h1,
.context h2,
.context h3 {
  font-size: 36px;
  line-height: 48px;
  word-spacing: -1px;
  font-weight: 700;
  margin: 0;
}

.imageleft {
  margin-left: -155px;
}

.announcementbox {
  background: var(--Primary);
  padding: 64px;
  border-radius: 16px;
  text-align: center;
  row-gap: 32px;
  display: flex;
  flex-direction: column;
}

.announcementbox>p,
.announcementbox>h3 {
  color: var(--White);
}

.announcementbox p {
  margin: 0;
}

.announcementbox h3 {
  color: var(--Secondary);
  margin: 0;
}

.announcementbox .signupform {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
}

.announcementbox .signupform .button {
  background: var(--Secondary);
}

.fieldbox {
  max-width: 500px;
  row-gap: 24px;
  flex-direction: column;
  display: flex;
  background: var(--Peach);
  padding: 64px;
  border-radius: 16px;
}

.fieldsubbox {
  display: flex;
  column-gap: 24px;
}

.fieldsubbox h4 {
  font-size: 22px;
  line-height: 34px;
  margin: 0;
  color: var(--Secondary);
}

.fieldsubbox p {
  font-size: 14px;
  line-height: 22px;
  color: var(--Black);
  margin: 0;
}

hr {
  z-index: 0;
}

.inpcon {
  display: flex;
  column-gap: 32px;
  row-gap: 16px;
}

.adv-img,
.announcementbox {
  width: 450px;
}

.taxsmartdiv {
  display: flex;
  margin-top: 32px;
}

.taxsmartdiv>div {
  width: 50%;
  row-gap: 8px;
  display: flex;
  flex-direction: column;
}

.downsidediv h5 {
  margin: 0;
}

.footer {
  padding: 16px;
  text-align: center;
  color: #666;
}

.navbarmenu a.button {
  width: 155px;
  height: 50px;
  background-color: var(--Primary);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  display: inline-block;
  text-align: center;
  line-height: 36px;
}

.article.context {
  padding-right: 128px;
}

.demoscreen {
  justify-content: space-between;
}

.demoscreen .image {
  margin-right: -128px;
}

.linesection {
  width: 100%;
}

.contactselection {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.demoscreen .context {
  max-width: 500px;
}

.doubleinput {
  display: flex;
  column-gap: 32px;
}

.demoform {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.buttonsection {
  display: flex;
  justify-content: center;
  column-gap: 32px;
}

.userselection {
  text-align: left;
}

.context.hide {
  display: none;
}

.context .pagehead {
  font-size: 40px;
  line-height: 54px;
}

.context h4 {
  font-size: 20px;
  line-height: 30px;
}

.context .pagehead span {
  font-size: 30px;
  line-height: 36px;
  display: inline-block;
}

.context h4 {
  margin: 0;
}

.imageleft img,
.advimg img,
.image img {
  max-width: 100%;
  height: auto;
}

.context {
  row-gap: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.boxcont p,
.context p {
  font-size: 16px;
  color: #666;
  margin: 0;
  line-height: 26px;
}

.context p.bannerhigh {
  font-size: 13px;
  color: #999;
  font-weight: 700;
  text-transform: uppercase;
  word-spacing: 1px;
}

.context h1,
.context h2,
.context h3 {
  font-size: 36px;
  line-height: 48px;
  word-spacing: -1px;
  font-weight: 700;
  color: var(--Primary);
  margin: 0;
}

.hr {
  z-index: 0;
}

.advimg,
.announcementbox {
  width: 450px;
}

.navbarmenu a.button {
  width: 155px;
  height: 50px;
  background-color: var(--Primary);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  display: inline-block;
  text-align: center;
  line-height: 36px;
}

.article.context {
  padding-right: 128px;
}

.pilotscreen .ITEM {
  display: none;
}
/* 
.bannertext {
  width: 100%;
  height: 60px;
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  padding: 0 100px;
} */

.sectiondivide {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 128px;
  padding: 100px 200px;
  background-image: url('/..//app/Assets/line_bg.svg');
  background-position: center center;
  background-size: calc(100% - 200px);
  background-repeat: repeat-y;
}

.sectiondivide.vcolumn {
  flex-direction: column;
}

.sectiondivide.vt {
  align-items: flex-start;
}

.protection {
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;
  position: relative;
}

.protection h2 {
  margin: 0;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 32px;
  color: var(--Black);
}

.protection p {
  color: #666;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
  font-weight: 300;
  max-width: 936px;
}

.protection-img {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}

.protection:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center left;
}

.context p {
  font-size: 16px;
  color: #666;
  margin: 0;
  line-height: 26px;
}

.imageleft {
  margin-left: -100px;
}

.announcementbox p {
  margin: 0;
}

.announcementbox h3 {
  color: var(--Secondary);
  margin: 0;
}

.announcementbox .signupform {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
}

.announcementbox .signupform .button {
  background: var(--Secondary);
}

.field-box {
  max-width: 500px;
  row-gap: 24px;
  flex-direction: column;
  display: flex;
  background: var(--Peach);
  padding: 64px;
  border-radius: 16px;
}

.field-subbox {
  display: flex;
  column-gap: 24px;
}

.field-subbox h4 {
  font-size: 22px;
  line-height: 34px;
  margin: 0;
  color: var(--Secondary);
}

.field-subbox p {
  font-size: 14px;
  line-height: 22px;
  color: var(--Black);
  margin: 0;
}

.adv-img,
.announcementbox {
  width: 450px;
}

.faqcon {
  display: flex;
  flex-direction: column;
  /* row-gap: 16px; */
  width: 100%;
}

.faqcon .faq {
  background: var(--White);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
}

.faqcon .faq h4 {
  margin: 0;
  color: var(--Black);
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
}

.faqcon .faq h4 span {
  float: right;
  font-size: 24px;
  line-height: 24px;
  color: var(--Primary);
}

.faqcon .faq h4 span.minus {
  font-size: 30px;
}

.faqcon .faq .content {
  padding: 16px 24px;
  border-top: 1px #efefef solid;
  max-width: 100%;
  line-height: 24px;
}

.faqcon .faq .content>div {
  max-width: 100%;
}

.content>.faq {
  margin-bottom: 8px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  background: var(--Bggrey);
}

.content>.faq.faq:last-child {
  margin-bottom: 0;
}

.faqcon .faq .content a {
  text-decoration: none;
  color: var(--Secondary);
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
  max-width: 100%;
}

.faqcon .faq .content p,
.faqcon .faq .content ul {
  color: var(--Grey);
  line-height: 24px;
  /* margin: 0 !important; */
  max-width: 100%;
}

.faqcon .faq .content img {
  max-width: 100%;
  height: auto;
  float: left;
  margin: 16px 0;
}

.faqcon .faq .content ul {
  margin: 8px 0;
  padding-left: 16px;
}

.faqcon .faq .content ul li {
  margin-bottom: 8px;
  line-height: 24px;
}

.faqcon .faq .content > :nth-child(1) {
  margin-bottom: 24px;
}

.faqcon .faq .content > :nth-child(2) {
  margin-bottom: 24px;
}

.faqcon .faq .content > :nth-child(3) {
  margin: 0;
}

.faqcon .faq .content table {
  margin: 32px auto;
}

.faqcon .faq .content table tr:nth-child(2n+1) {
  background: #f5f5f5;
}

.faqcon .faq .content table tr th {
  padding: 16px 32px;
  color: var(--Primary);
  text-transform: capitalize;
  text-align: center;
}

.faqcon .faq .content table tr th:first-child {
  text-align: left;
}

.faqcon .faq .content table tr td {
  padding: 16px 32px;
  text-align: center;
}

.faqcon .faq .content table tr td:first-child {
  text-align: left;
}

.faqcon.big {
  row-gap: 30px;
}

.faqcon.big .faq h4 {
  font-size: 20px;
  padding: 24px 32px;
}

.faqcon.big .faq .content {
  margin-top: 0px;
  padding: 0 24px;
}

.faqdet {
  align-items: flex-start;
  column-gap: 48px;
}

.catlist {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.catlist .catname {
  display: inline-block;
  padding: 12px 10px 10px;
  border-radius: 4px;
}

.catlist .catname.active {
  background: var(--Secondary);
  color: var(--White);
}

.faqlist {
  display: flex;
  flex: 1;
  max-width: 100% !important;
  width: calc(100% - 300px);
}

.input-search {
  margin-bottom: 16px;
}

.riskButtons .new_row {
  display: flex;
}

.react-datepicker-wrapper input, .input-gray {
  background: #f1f1f1;
  margin-top: 10px;
  padding: 16px;
  border-radius: 4px;
  border: none;
  color: var(--Black);
  width: 100%;
  box-sizing: border-box;
}

.input-gray.input-value {
  background: none;
  font-size: 18px;
  padding: 12px 0;
  font-weight: 600;
  color: var(--Primary);
}

.inputs>div {
  width: calc(18% - 26px);
  min-width: 200px;
}

.inputs>div.buttonbuy {
  min-width: 240px;
  display: block;
  flex-direction: column;
  align-content: flex-start;
  flex: 1;
}

.inputs>div.buttonbuy button {
  padding: 12px 16px;
  margin-top: 32px;
  justify-content: center;
  margin-bottom: 8px;
  width: auto;
}

.inputs>div.buttonbuy p {
  margin: 0;
}

.inputs>div.big {
  width: calc(23% - 26px);
  min-width: 275px;
}

.inputs label {
  color: var(--Primary);
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  column-gap: 8px;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.align-center {
  justify-content: center;
}

.riskButtons {
  row-gap: 10px;
  display: flex;
  flex-direction: column !important;
}

.projectedvalue {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.body-message p {
  color: var(--Black);
}

.body-message {
  padding: 16px 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  float: left;
  margin-top: 44px;
  background: none;
}

.heading span {
  flex: 1;
}

.sectiondivide.tablepart {
  align-items: flex-start;
  column-gap: 48px;
}

.sectiondivide.tables {
  align-items: normal;
  padding-bottom: 10px !important;
}

.ai-guaranteed-table,
.ai-affordable-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.ai-guaranteed-results .ai-static-content,
.ai-affordable-results .ai-static-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.ai-guaranteed-results .ai-header,
.ai-affordable-results .ai-header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  margin: 0 0 12px 0;
  text-align: center;
  color: var(--Primary);
  text-transform: uppercase;
}

.ai-guaranteed-results .ai-header span,
.ai-affordable-results .ai-header span {
  text-transform: none;
  font-size: 14px;
  display: inline-block;
  margin-left: 8px;
  font-family: "Inter";
  font-weight: 400;
  font-style: italic;
}

.ai-guaranteed-results .ai-helpText,
.ai-affordable-results .ai-helpText {
  margin: 0;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.32);
  text-align: center;
}

.ai-guaranteed-table .ai-card-header,
.ai-affordable-table .ai-card-header {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 4;
}

.ai-guaranteed-table .ai-card-header h4,
.ai-affordable-table .ai-card-header h4 {
  display: inline-block;
  margin: 0 0 8px 0;
  font-size: 24px;
  text-transform: uppercase;
  color: var(--Primary);
  position: relative;
  width: fit-content;
}

.ai-affordable-table .ai-card-header h4 {
  color: var(--Secondary);
}

.ai-guaranteed-table .ai-card-header h4:after,
.ai-affordable-table .ai-card-header h4:after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--Primary);
  opacity: 0.6;
}

.ai-guaranteed-table .ai-card-header p,
.ai-affordable-table .ai-card-header p {
  margin: 4px 0 0 0;
  font-size: 14px;
  font-style: italic;
}

.ai-selfassembly-table {
  background: var(--White);
  display: flex;
  flex: 1;
  width: 100%;
  overflow: auto;
}

.ai-selfassembly-table table {
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
}

.ai-selfassembly-table table thead tr th {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--Primary);
  font-family: "Inter";
  font-weight: 700;
  white-space: nowrap;
}

.ai-selfassembly-table table tbody {
  overflow-y: auto;
}

.ai-selfassembly-table table tbody::-webkit-scrollbar {
  width: 8px;
  background-color: var(--Peach);
  border-radius: 8px;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.ai-selfassembly-table table tbody::-webkit-scrollbar-thumb {
  background-color: var(--Primary);
  border-radius: 8px;
}

.ai-selfassembly-table table tbody tr td {
  color: #5b5b5b;
  font-size: 14px;
}

.ai-selfassembly-table table thead tr th,
.ai-selfassembly-table table tbody tr td {
  text-align: right;
  padding: 16px;
  white-space: nowrap;
}

.ai-selfassembly-table table tbody tr:nth-child(2n + 1) {
  background: #fafafa;
}

.ai-selfassembly-table table thead tr th:nth-child(1),
.ai-selfassembly-table table tbody tr td:nth-child(1) {
  font-weight: 700;
  color: var(--Primary);
}

.ai-selfassembly-table table thead tr th:nth-child(1),
.ai-selfassembly-table table tbody tr td:nth-child(1),
.ai-selfassembly-table table thead tr th:nth-child(2),
.ai-selfassembly-table table tbody tr td:nth-child(2) {
  text-align: left;
}

.ai-selfassembly-table table tbody tr td:nth-child(2) {
  font-family: "Inter";
  font-weight: 700;
}

.ai-guaranteed-results {
  background-color: var(--Bgprimary);
}

.ai-selfassembly-cost {
  display: flex;
  padding: 8px 16px;
  flex-wrap: wrap;
  flex-direction: column;
  color: var(--Navy-Blue);
  font-size: 18px;
  font-weight: bold;
  min-height: 70px;
}

.ai-selfassembly-cost p {
  margin-top: 10px;
  margin-bottom: 0;
}

.ai-selfassembly-cost .info {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

.ai-guaranteed-results,
.ai-affordable-results {
  display: flex;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.ai-guaranteed-results .selfassambly-body,
.ai-affordable-results .selfassambly-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ai-affordable-results {
  background-color: var(--Bgsecondary);
}

.getalerts {
  background: var(--Primary);
  padding: 24px;
  color: var(--White);
  border-radius: 8px;
  display: flex;
  column-gap: 40px;
}

.getalerts .input-type-text-area-sm {
  min-width: 300px;
}

.getalerts .button {
  width: auto;
  background: var(--Secondary);
}

.getalerts h3 {
  font-size: 22px;
  font-weight: 500;
}

.aftermark {
  padding-top: 16px;
  padding-bottom: 0;
}

.aftermark .weatherstat.warning {
  margin: 0;
  padding: 0;
}

.aftermark .weatherstat.warning p {
  margin: 0;
  text-align: left;
}

.portfolioinp {
  text-align: left;
  padding: 16px 8px;
  width: 100%;
}

.portfolioinp table {
  width: 100%;
}

.portfolioinp td,
.portfolioinp th {
  padding: 0 8px;
  vertical-align: top;
}

.portfolioinp td.bigth,
.portfolioinp th.bigth {
  min-width: 450px;
}

.portfolioinp input {
  padding: 12px 16px;
}

.portfolioinp tfoot td {
  padding: 16px 8px 0;
}

.portfolioinp .ai-btn {
  display: inline-block;
}

.portfolioinp .infowarning {
  background: var(--Bgsecondary);
  display: inline-block;
  margin-left: 16px;
  padding: 8px 16px;
}

.portfolioinp .infowarning img {
  margin-right: 8px;
}

.inpinfo {
  color: var(--Grey);
  font-size: 14px;
  padding-top: 4px;
  display: inline-block;
}

.ai-affordable-results .ai-guaranteed-table .ai-card-header h4 {
  color: var(--Secondary);
}

.price-Guaranteed {
  color: var(--Secondary);
  font-weight: bold;
}

.price-Market {
  color: var(--Primary);
  font-weight: bold;
}

.text-big {
  font-size: 22px;
  line-height: 32px;
}

.text-c {
  text-align: center;
}

.line-box {
  position: relative;
  overflow: hidden;
}

.line-container {
  width: 100%;
  float: left;
  padding: 0px 100px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.line-range {
  width: 1px;
  height: 100%;
  border-right: 1px solid #EBEBEB;
}

.line-range:nth-child(2),
.line-range:nth-child(3),
.line-range:nth-child(4) {
  border-right: 1px dashed #ebebeb;
}

.adaptive-help {
  padding-top: 250px;
  position: relative;
  background-position: top left;
  background-repeat: no-repeat;
  background-color: #FFFAF9;
}

.adaptive-help-left {
  position: relative;
  z-index: 10;
  padding-right: 95px;
}

.adaptive-help-right {
  position: relative;
  z-index: 9;
}

.adaptive-help:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  z-index: 1;
}

.adaptive-help:before {
  content: "";
  width: 100%;
  height: 100%;
  background-position: right center;
  background-repeat: no-repeat;
  position: absolute;
}

.adaptive-help-left h2 {
  margin: 0;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 24px;
  color: var(--Black);
}

.adaptive-help-left p {
  color: #666;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
  font-weight: 300;
}

.adaptive-help-left .main-header.signupform {
  margin-top: 64px;
}

.contact-new {
  padding-top: 100px;
}

.contact-new:before,
.contact-new:after {
  display: none;
}

.contact-new .adaptive-help-left {
  padding-right: 0px;
  z-index: 99;
}

.welcome {
  background: var(--Primary);
  padding: 32px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.welcome h2 {
  margin: 0px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
}

.welcome h2 a {
  color: #fff;
}

.duration {
  display: flex;
  column-gap: 32px;
  row-gap: 24px;
  flex-wrap: wrap;
  width: 100%;
}

.duration button {
  font-size: 14px;
  line-height: 20px;
  color: #3e4968;
  border: 1px #3e4968 solid;
  padding: 8px 16px;
  font-weight: 600;
  background: none;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.duration button.selected {
  background: var(--Primary);
  color: var(--White);
  border: none;
}

.weatherstat {
  display: flex;
  width: 100%;
  max-width: 1024px;
  border-radius: 8px;
  align-items: center;
  column-gap: 32px;
  flex-wrap: wrap;
  flex-direction: column;
}

.weatherstat.part2 {
  align-items: flex-start;
}

.weatherstat.part2 p {
  color: var(--Grey);
  text-align: left;
  display: block;
}

.weatherstat.part3 {
  margin-bottom: 48px;
}

.weatherstat.part3 h4 {
  text-align: center;
  font-size: 18px;
}

.weatherstat.warning {
  margin: 0;
  align-items: flex-start;
  max-width: 100%;
}

.weatherstat.warning p {
  margin-top: -50px;
  font-style: italic;
  font-size: 14px;
  color: var(--Primary);
}

.stat {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.stat img {
  height: 60px;
}

.weatherstat p {
  display: flex;
  flex: 4;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin-top: 0;
}

.weatherstat h3 {
  font-size: 24px;
  margin: 0;
  line-height: 36px;
  font-weight: 500;
}

.weatherstat h4 {
  font-size: 22px;
  margin-bottom: 10px;
}

.weatherstat.signform {
  margin: 24px 0;
}

.weatherstat.signform h3 {
  margin-bottom: 16px;
}

.weatherstat.text-center h3 {
  text-align: center;
}

.riskalert {
  display: flex;
  align-items: center;
  justify-content: center;
}

.details_wrap {
  width: 100%;
}

.shieldstates {
  padding: 0 16px;
}

.shieldstates .contentshield {
  width: 100%;
}

.shieldstates .contentshield .subnavbarmenu {
  width: 100%;
  padding: 0 16px;
  background: var(--Bgprimary);
  position: static;
  padding: 0 16px;
}

.shield-row {
  display: flex;
  column-gap: 48px;
  row-gap: 24px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.ai-shield-calculator-container {
  background-color: var(--White);
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 24px;
}

.ai-shield-calculator-container * {
  box-sizing: border-box;
}

.ai-shield-calculator-container .ai-shield-calculator-select select {
  appearance: none;
  border: none;
  padding: 0 28px 4px 0;
  font-size: 20px;
  color: var(--Secondary);
  position: relative;
  border-radius: 0;
  border-bottom: 2px solid var(--Secondary);
  background: var(--White);
  width: 100%;
  font-weight: 700;
  margin: 8px 0;
}

.ai-shield-calculator-container .ai-shield-calculator-select select:focus {
  outline: none;
}

.ai-shield-calculator-container .ai-shield-calculator-select select option {
  font-size: 16px;
  color: var(--Navy-Blue);
}

.ai-shield-calculator-container .ai-shield-calculator-select .ai-shield-calculator-select-arrow {
  position: absolute;
  top: 30px;
  right: 0px;
  display: block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}

.forwardtest {
  display: flex;
  padding: 32px 0 0;
  column-gap: 32px;
  row-gap: 48px;
  margin-bottom: 16px;
  flex-wrap: wrap;
  width: 100%;
}

.forwardtest .forgraphs {
  width: calc(50% - 24px);
  row-gap: 32px;
  display: flex;
  flex-direction: column;
}

.ProjectionApp {
  margin-top: 16px;
  background-color: var(--White);
  border: 1px solid #ffffff;
  position: relative;
  padding-left: 130px;
}

.axisX text {
  text-anchor: start;
  font-size: 12px;
  font-weight: 700;
  color: var(--Primary);
}

.baseGridLine line {
  stroke: rgba(255, 255, 255, 0.5);
}

.react-tooltip-lite {
  background: #333;
  color: white;
}

.react-tooltip-lite a {
  color: #86b0f4;
  text-decoration: none;
}

.react-tooltip-lite a:hover {
  color: #4286f4;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

.customTip .react-tooltip-lite {
  border: 1px solid #888;
  background: #ccc;
  color: black;
}

.customTip .react-tooltip-lite-arrow {
  border-color: #444;
  position: relative;
}

.customTip .react-tooltip-lite-arrow::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.customTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.customTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid #ccc;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.customTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.customTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid #ccc;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.arrow-content-tooltip .react-tooltip-lite {
  box-sizing: border-box;
  border: 1px solid gray;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
}

.arrow-content-tooltip .react-tooltip-lite-down-arrow svg {
  transform: translateY(1px);
}

.arrow-content-tooltip .react-tooltip-lite-right-arrow svg {
  transform: rotate(270deg) translateY(-4px) translateX(-4px);
}

.arrow-content-tooltip .react-tooltip-lite-up-arrow svg {
  transform: rotate(180deg) translateY(1px);
}

.arrow-content-tooltip .react-tooltip-lite-left-arrow svg {
  transform: rotate(90deg) translateY(5px) translateX(4px);
}

.tooltip {
  background-color: var(--White);
}

.contentBackground {
  background-color: var(--White);
}

.tooltip text {
  font-size: 12px;
  fill: #696969;
}

.tooltipLine {
  stroke: var(--Primary);
  stroke-dasharray: 3px;
  stroke-width: 1px;
}

.tooltipContent .contentTitle {
  font-weight: 700;
}

.tooltipLinePoint:nth-child(3) {
  fill: #38BB55;
}

.tooltipLinePoint:nth-child(4) {
  fill: #666666;
}

.tooltipLinePoint:nth-child(5) {
  fill: var(--Primary);
}

.tooltipLinePoint:nth-child(6) {
  fill: #e58b76;
}

.legendContainer {
  display: flex;
  justify-content: center;
  color: var(--White);
  padding: 24px 32px 0;
}

.checkbox {
  margin: 10px;
}

.porfoliovaluesline,
.porfoliovalues {
  position: absolute;
  height: 100%;
  left: 0;
  border-right: 2px var(--Primary) solid;
  width: 190px;
}

.porfoliovalues div {
  color: var(--Navy-Blue);
  text-align: right;
  width: 100%;
  padding-right: 20px;
}

.min_val {
  position: absolute;
  bottom: 20%;
}

.porfoliovaluesline {
  width: 100%;
  border: none;
}

.porfoliovaluesline:after {
  position: absolute;
  content: "";
  display: block;
  border: 1px #666 dashed;
  width: calc(100% - 220px);
  right: 30px;
  bottom: 23%;
}

.porfoliovaluesline:before {
  position: absolute;
  content: "";
  display: block;
  border: 1px #666 dashed;
  width: calc(100% - 220px);
  right: 30px;
  top: 22%;
}

.start_val {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.chartimg {
  z-index: 2;
  position: relative;
}

.max_val {
  position: absolute;
  top: 20%;
}

.port_val {
  color: var(--Primary);
  font-weight: 600;
  text-transform: uppercase;
  height: 100%;
  position: absolute;
  width: 20px;
  left: 0;
}

.port_date {
  color: var(--Primary);
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  width: calc(100% - 220px);
  right: 30px;
  bottom: 0;
  text-align: center;
}

.port_date:after {
  position: absolute;
  content: "";
  display: inline-block;
  height: 1px;
  background: var(--Primary);
  width: 100%;
  left: 0;
  margin-top: 10px;
}

.port_val:after {
  content: "";
  display: inline-block;
  width: 1px;
  background: var(--Primary);
  height: 100%;
  margin-left: 10px;
}

.port_val span {
  transform: rotate(-90deg);
  display: block;
  white-space: nowrap;
  width: 172px;
  position: absolute;
  left: -75px;
  top: 47%;
  background: #fff;
  padding: 0 12px;
}

.port_date span {
  display: inline-block;
  white-space: nowrap;
  background: #fff;
  padding: 0 12px;
  z-index: 2;
  position: relative;
}

.risk-cont {
  width: 100%;
  background-color: var(--White);
  padding: 0;
  flex-direction: column;
}

.risk-cont .portfolio-table,
.risk-cont .self_assembly.details_wrap {
  width: 100%;
}

.contributio-table th {
  padding: 8px 16px;
}

.contributio-table th div {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.contributio-table table {
  max-width: 100%;
  width: 100%;
}

.contributio-table td:nth-child(4),
.contributio-table td:nth-child(5),
.contributio-table td:nth-child(6) {
  color: var(--Navy-Blue);
}

.contributio-table th,
.contributio-table td {
  font-weight: 500;
  font-size: 14px;
}

.contributio-table th:nth-child(1),
.contributio-table td:nth-child(1) {
  max-width: 50px;
}

.contributio-table th td:nth-child(6),
.contributio-table td td:nth-child(6) {
  color: var(--Navy-Blue);
}

.contributio-table th:nth-child(1),
.contributio-table td:nth-child(1),
.contributio-table th:nth-child(2),
.contributio-table td:nth-child(2),
.contributio-table th:nth-child(3),
.contributio-table td:nth-child(3) {
  text-align: left;
}

.contributio-table th:nth-child(4),
.contributio-table td:nth-child(4),
.contributio-table th:nth-child(5),
.contributio-table td:nth-child(5),
.contributio-table th:nth-child(6),
.contributio-table td:nth-child(6) {
  text-align: right;
}

.contributio-table tfoot {
  background: var(--Bgprimary);
}

.contributio-table tfoot td {
  color: var(--Primary);
  font-weight: bold;
  padding: 16px;
}

.contributio-table tfoot td:nth-child(2),
.contributio-table tfoot td:nth-child(3) {
  text-align: right;
}

.riskhead {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 32px 0;
  width: 100%;
}

.riskhead .riskstate h3 {
  color: var(--Secondary);
  font-size: 34px;
  margin: 0;
}

.riskhead .riskstate .lblrisk div {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.halorisk{
  padding-top: 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  flex-direction: column;
  row-gap: 8px;
}
.halorisk > div{
  flex-direction: row;
  display: flex;
  column-gap: 16px;
  align-items: center;
}
.riskhead .riskstate.halorisk h3 {
  font-size: 20px;
  color: var(--Primary);
}

.riskhead h2 {
  color: var(--Primary);
  font-size: 26px;
  line-height: 30px;
  display: flex;
  flex: 1;
}

.riskmodes {
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.riskmodes div {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.riskmodes span {
  display: inline-block;
}

.riskmodes .risk {
  width: 16px;
  height: 16px;
  background: var(--Primary);
}

.riskmodes .position {
  width: 16px;
  height: 16px;
  background: var(--Secondary);
  border-radius: 16px;
}

.riskmodes .vix {
  width: 32px;
  height: 4px;
  background: var(--Blue);
}

.riskmodes .vix1 {
  width: 15px;
  height: 4px;
  background: var(--Blue);
}

.table_head {
  border: 1px var(--Bggrey) solid;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 48px;
  margin-bottom: 32px;
}

.table_head .table_height {
  /* max-height: 300px; */
  max-height: calc(10 * 62px);
}

.table_head h3 {
  background: var(--Bgprimary);
  margin: 0;
  padding: 16px;
  color: var(--Primary);
  cursor: pointer;
  font-size: 22px;
}

.table_head h3 span {
  float: right;
}

.riskchart {
  margin: 20px 0;
  max-height: 500px;
  position: relative;
  padding: 0 24px;
}

.m0i{
  margin: 0 !important;
}
.mb0i{
  margin-bottom: 0 !important;
}
.mt0i{
  margin-top: 0 !important;
}
.p0i {
  padding: 0 !important;
}

.pt0i {
  padding-top: 0 !important;
}
.contentcol{
  align-items: flex-start;
}
.contentcol h2{
  margin-bottom: 0;
}
.mt30 {
  margin-top: 30px;
}
.mt64 {
  margin-top: 64px;
}

.chartlbl {
  position: absolute;
  top: 50%;
  transform: rotate(-90deg);
  width: 137px;
  height: 16px;
  text-align: center;
  color: var(--Primary);
  font-weight: 600;
  margin-top: -40px;
}

.chartlbl.left {
  left: -55px;
}

.chartlbl.right {
  right: -55px;
}

.forwardtest .forgraphs h3 {
  font-size: 20px;
  color: var(--Navy-Blue);
  align-items: center;
  display: flex;
  margin: 16px 0;
  column-gap: 8px;
}

.forwardtest .forgraphs h3 img {
  max-width: 20px;
}

.forwardtest .forgraphs .forward-column {
  display: flex;
  flex-direction: column;
}

.forwardanalyze-portfolio {
  width: calc(50% - 24px);
}

.forwardanalyze-portfolio .tab-content {
  height: 100%;
  flex-direction: column;
}

.forwardanalyze-portfolio .tab-content>div {
  height: 100%;
  width: 100%;
}

.forwardanalyze-portfolio .barchart {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
}

.forwardanalyze-portfolio .barchart img {
  width: 100%;
}

.infomessage {
  margin-top: 0;
}

.dataforwardtest {
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 48px;
}

.subnavbarmenu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 48px;
  align-items: center;
  height: 100%;
  flex: 1;
}

.riskalert.full {
  width: 100%;
}

.riskalert .signupform {
  width: auto;
  max-width: 100%;
}

.riskalert .signupform>div {
  width: 100%;
}

.bigbtn button {
  min-width: 200px;
}

.m0 {
  margin: 0;
}

.riskText {
  width: 100px;
}

.sec-head {
  width: 100%;
  font-size: 36px;
  line-height: 48px;
  color: var(--Primary);
  text-align: center;
  margin: 0 0 16px;
}

.sec-head.left {
  text-align: left;
}

.sec-head.small {
  font-size: 26px;
  line-height: 36px;
}

.sec-title {
  display: flex;
  font-size: 26px;
  line-height: 36px;
  font-weight: bold;
}

.sec-title h3 {
  flex: 1;
  text-align: left;
}

.sec-title .indicator {
  display: flex;
  color: var(--Primary);
  align-items: center;
  column-gap: 16px;
}

.sec-title .indicator::before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 5px;
  background: var(--Primary);
}

.signupalert {
  box-shadow: 1px 10px 60px rgba(0, 0, 0, 0.06);
  background: var(--White);
  padding: 32px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.signupalert h3 {
  font-size: 24px;
  margin: 0;
}

.signupalert h3 span {
  margin-top: -300px;
  padding-bottom: 300px;
  display: block;
}

.signupalert p {
  color: var(--Grey);
}

.signupalert .input-type-text-area-sm {
  width: 300px;
  max-width: 100%;
}

.signupalert .reminder {
  display: flex;
  margin: 10px 0;
  width: 780px;
  max-width: 100%;
  column-gap: 24px;
  row-gap: 8px;
}

.signupalert .reminder h4 {
  margin: 0;
  color: var(--Primary);
  min-width: 130px;
  text-transform: uppercase;
}

.signupalert .reminder p {
  margin: 0;
  flex: 1;
  color: var(--Black);
}

.signupalert .reminder .checkcon {
  height: 24px;
}

.signupalert .riskalert {
  margin-top: 16px;
}

.iconcon {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 30px;
  justify-content: center;
}

.iconcon .iconset {
  background: #FFF9F8;
  width: calc(25% - 22.5px);
  text-align: center;
  padding: 50px 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
  min-width: 280px;
}

.iconcon .iconset:hover {
  background: var(--Secondary);
}

.iconcon .iconset:hover svg path {
  fill: var(--White) !important;
}

.iconcon .iconset:hover h5 {
  color: var(--White);
}

.iconcon .iconset h5 {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}

.ptsec {
  padding-top: 40px;
  padding-bottom: 40px;
}

.dialcenter {
  text-align: center;
}

.vixdail {
  position: relative;
  width: 400px;
  text-align: center;
  display: inline-block;
}

.vixdail .dailcircle {
  position: absolute;
  transform: translate(-50%, -50%) rotate(127deg);
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: 22px;
}

.vixdail span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 20px;
}

.vixdail span h3 {
  font-size: 44px;
  margin: 0;
  font-weight: bold;
}

.vixdail span h5 {
  font-size: 28px;
  margin: 0;
  font-weight: bold;
}

.tablecon {
  max-width: 100%;
  overflow: auto;
}

.collapse {
  margin-bottom: 48px;
  border: 1px var(--Bgprimary) solid;
  border-radius: 8px;
}

.collapse h4 {
  margin: 0;
  padding: 16px;
  background: var(--Bgprimary);
  border-radius: 8px 8px 0 0;
  color: var(--Primary);
  cursor: pointer;
}

.collapse h4 span {
  float: right;
}

.collapse p {
  padding: 8px 16px;
}

.weatherstat h3 {
  font-size: 22px;
  margin: 0;
  line-height: 36px;
  font-weight: 500;
}

.sec-title .indicator {
  display: flex;
  color: var(--Secondary);
  align-items: center;
  column-gap: 16px;
}

.announcementbox {
  background: var(--Primary);
  padding: 64px;
  border-radius: 16px;
  text-align: center;
  row-gap: 32px;
  display: flex;
  flex-direction: column;
}

.announcementbox>p {
  color: var(--White);
}

.sectiondivide.section-col {
  flex-direction: column;
  row-gap: 16px;
  padding-top: 50px;
}

.sectiondivide.section-col>div {
  max-width: 100%;
  width: 100%;
}

.pt20 {
  padding-top: 20px !important;
}

.apisection {
  column-gap: 48px;
  row-gap: 48px;
  align-items: flex-start;
}

.apisection .apilist {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  background: var(--White);
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.apisection .apilist .tablist {
  padding: 12px 16px 8px;
  color: var(--Gray);
  border-radius: 4px;
  width: 280px;
  cursor: pointer;
}

.apisection .apilist .tablist.active {
  background: var(--Bgprimary);
  color: var(--Primary);
}

.apisection .apidata {
  width: 350px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.apisection .apidata .collapse h4 {
  padding: 8px 16px;
}

.apisection .apidata .collapse h4 span {
  float: right;
}

.apisection .apidata .portfoliolbl {
  display: flex;
  align-items: center;
}

.apisection .apidata .portfoliolbl label {
  width: 240px;
}

.apisection .apidata .portfoliolbl .input-gray {
  margin: 0;
}

.apisection .apidata .portfoliodata {
  margin: 0;
}

.apisection .apidata .error-message {
  margin: 0;
  padding: 8px 0 0;
}

.apisection .apioutput {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 32px;
}

.apisection .apioutput .code {
  width: 100%;
  min-height: 220px;
  background: var(--Bgsecondary);
  border-radius: 8px;
  padding: 16px 16px 64px;
  position: relative;
  row-gap: 8px;
  display: flex;
  flex-direction: column;
}

.apisection .apioutput .code label {
  font-size: 16px;
  font-weight: 600;
  color: var(--Primary);
}

.apisection .apioutput .code button {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.apisection .apioutput .code pre {
  display: block;
  width: 100%;
  height: auto;
  flex: 1;
  overflow: auto;
  font-family: "Overpass", sans-serif;
  font-size: 14px;
  line-height: 22px;
}

.apipop h1 {
  font-size: 20px;
  margin: 0;
  text-align: center;
}

.apipop p {
  margin: 0;
  text-align: center;
}

.apipop .pop-up-text {
  margin-top: 16px;
}

.inputcon {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
}

.inputcon .input-gray {
  margin: 0;
}

.inputcon .error-message {
  padding: 0 !important;
}

.ovauto {
  overflow: auto;
  width: 100%;
}

.tikerdata tbody tr:first-child .removeticker {
  display: none;
}

.tikerdata tbody tr .removeticker {
  display: inline-block;
  cursor: pointer;
  padding: 8px;
}

.tikerdata tbody tr .removeticker img {
  float: left;
}

.portfolioinp td.bigth,
.portfolioinp th.bigth {
  min-width: 400px;
}

#app {
  width: 350px;
}

@keyframes RingProgress {
  0% {
    stroke-dasharray: 15 100;
  }
}

.ActivityRings {
  height: 100%;
  width: 100%;
}

.ActivityRings .ring {
  transform-origin: 50%;
}

.ActivityRings .completed {
  animation: RingProgress 1s ease-in-out forwards;
  stroke-linecap: round;
}

.ActivityRings circle {
  fill: none;
}

.ring1-green .background {
  stroke: #ffffff;
}

.ring1-green .completed {
  stroke: #38bb55;
}

.ring1-yellow .background {
  stroke: #ffffff;
}

.ring1-yellow .completed {
  stroke: #ffa654;
}

.ring1-red .background {
  stroke: #ffffff;
}

.ring1-red .completed {
  stroke: #ed6663;
}

.ring1-dark-red .background {
  stroke: #ffffff;
}

.ring1-dark-red .completed {
  stroke: #800000;
}

.ring2-green .background {
  stroke: #ffffff;
}

.ring2-green .completed {
  stroke: #38bb55;
}

.ring2-yellow .background {
  stroke: #ffffff;
}

.ring2-yellow .completed {
  stroke: #ffa654;
}

.ring2-red .background {
  stroke: #ffffff;
}

.ring2-red .completed {
  stroke: #ed6663;
}

.ring2-dark-red .background {
  stroke: #ffffff;
}

.ring2-dark-red .completed {
  stroke: #800000;
}

.ring3-green .background {
  stroke: #ffffff;
}

.ring3-green .completed {
  stroke: #38bb55;
}

.ring3-yellow .background {
  stroke: #ffffff;
}

.ring3-yellow .completed {
  stroke: #ffa654;
}

.ring3-red .background {
  stroke: #ffffff;
}

.ring3-red .completed {
  stroke: #ed6663;
}

.ring3-dark-red .background {
  stroke: #ffffff;
}

.ring3-dark-red .completed {
  stroke: #800000;
}

.ring3:hover {
  cursor: pointer;
}

.dial_holder {
  fill: none;
}

.dial {
  margin-top: -296px;
  margin-left: 100px;
}

.dial img {
  position: absolute;
  left: 50%;
  margin-left: -64px;
  top: 50%;
  margin-top: -64px;
  z-index: 2;
}

.dail_state {
  margin-top: -188px;
  margin-bottom: -46px;
  margin-left: 43px;
  transform: scale(1.2);
  z-index: 0;
  position: relative;
}

.dial_holder {
  width: 128px;
  fill: white;
  stroke: black;
  stroke-width: 0;
  stroke-linecap: round;
  margin-left: 13px;
  margin-top: 40px;
  filter: drop-shadow(1px 1px 2px #666666);
}

.dial_holder1 {
  width: 106px;
  fill: white;
  stroke: black;
  stroke-width: 0;
  stroke-linecap: round;
  filter: drop-shadow(1px 1px 2px #666666);
}

.meter {
  fill: #ffffff;
  filter: drop-shadow(1px 1px 2px #666666);
  stroke-linecap: round;
  stroke: black;
  stroke-width: 0px;
}

.rightLineHolder {
  margin-left: 215px;
  margin-top: -4px;
  position: relative;
  z-index: 2;
}

.leftLineHolder {
  margin-left: 48px;
  margin-top: -54px;
  position: relative;
  z-index: 2;
}

.Line {
  stroke: #bababa;
  stroke-width: 2px;
}

.marks {
  transform: translate(20px, 25px);
  stroke-width: 0.5px;
}

.marks>line:nth-child(1) {
  transform: rotate(15deg);
}

.marks>line:nth-child(2) {
  transform: rotate(calc(2 * 15deg));
}

.marks>line:nth-child(3) {
  transform: rotate(calc(3 * 15deg));
}

.marks>line:nth-child(9) {
  transform: rotate(calc(9 * 15deg));
}

.marks>line:nth-child(10) {
  transform: rotate(calc(10 * 15deg));
}

.marks>line:nth-child(11) {
  transform: rotate(calc(11 * 15deg));
}

.marks>line:nth-child(12) {
  transform: rotate(calc(12 * 15deg));
}

.marks>line:nth-child(13) {
  transform: rotate(195deg);
}

.marks>line:nth-child(14) {
  transform: rotate(calc(14 * 15deg));
}

.marks>line:nth-child(15) {
  transform: rotate(calc(15 * 15deg));
}

.marks>line:nth-child(16) {
  transform: rotate(calc(16 * 15deg));
}

.marks>line:nth-child(17) {
  transform: rotate(calc(17 * 15deg));
}

.marks>line:nth-child(18) {
  transform: rotate(calc(18 * 15deg));
}

.marks>line:nth-child(19) {
  transform: rotate(calc(19 * 15deg));
}

.marks>line:nth-child(20) {
  transform: rotate(calc(20 * 15deg));
}

.marks>line:nth-child(21) {
  transform: rotate(calc(21 * 15deg));
}

.marks>line:nth-child(22) {
  transform: rotate(calc(22 * 15deg));
}

.marks>line:nth-child(23) {
  transform: rotate(calc(23 * 15deg));
}

.marks>line:nth-child(24) {
  transform: rotate(calc(24 * 15deg));
}

.riskLabels {
  text-align: center;
  font-size: 9.5px;
  line-height: 50%;
  margin-top: -45px;
  margin-bottom: 36px;
  color: #666666;
  z-index: 2;
  position: relative;
}

.shieldIcon {
  left: 50%;
  margin-left: -64px;
  top: 50%;
  margin-top: -64px;
  z-index: 2;
}

.riskButtons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.riskButtons .new_row {
  align-items: center;
  justify-content: center;
  column-gap: 8px;
}

.riskText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  letter-spacing: 0.289231px;
}

.riskTextButtons {
  border-radius: 3px;
  padding: 5px 8px;
  width: 120px;
  height: 35px;
  text-align: center;
}

.insideText {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 0px;
  letter-spacing: 0.289231px;
  color: var(--White);
  text-align: center;
  justify-content: center;
}

.is-sticky {
  position: fixed;
  z-index: 100;
  max-width: 100%;
  background: var(--White) !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.logo-nav {
  max-width: 100%;
  max-height: 44px;
  display: block;
}

.navbar {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  width: 100%;
}

.navwrap {
  display: flex;
  width: 100%;
  padding: 24px;
  column-gap: 32px;
  transition: all 0.2s ease-in-out;
}
.ShieldCardcon.cardcol h3{

}
.liststyle h4{
  color: var(--Secondary);
}
.navbarmenu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 48px;
  row-gap: 16px;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  flex: 1;
}

.navbarmenu a {
  font-weight: 500;
  font-size: 16px;
  color: var(--Black);
  text-decoration: none;
}

.navbarmenu a.active {
  border-bottom: 2px var(--Secondary) solid;
  color: var(--Primary);
}

.navbarmenu .submenu a.active {
  border-left: 2px var(--Primary) solid;
  border-bottom: none;
  background: var(--Bgsecondary);
}

a.navtoggle {
  width: 40px;
  height: 40px;
  padding: 10px 5px;
  display: none;
}

a.navtoggle span {
  width: 30px;
  background: #000;
  display: inline-block;
  height: 2px;
  float: left;
}

a.navtoggle span:nth-child(2) {
  margin: 8px 0;
}

a.navtoggle span .navbarmenu a.button.secondary {
  background: var(--Secondary);
}

ul.navbarmenu>li {
  position: relative;
  display: inline-block;
}

ul.navbarmenu>li.navbutton {
  display: flex;
  column-gap: 16px;
}

ul.navbarmenu>li a {
  padding: 8px 0;
  display: flex;
  align-items: center;
}

ul.navbarmenu>li .submenu a {
  padding: 16px;
  width: 100%;
}

ul.navbarmenu>li a:hover {
  background: var(--Bgprimary);
}

ul.submenu {
  position: absolute;
  list-style: none;
  display: none;
  top: 100%;
  left: 0;
  flex-direction: column;
  width: 260px;
  padding: 16px 0;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  border-bottom: 2px var(--Primary) solid;
  z-index: 999;
}

ul.submenu .navnotice{
  padding: 0 16px;
  font-size: 12px;
  font-weight: 500;
}
ul.submenu .button{
  float: left !important;
}

ul.submenu li {
  width: 100%;
}

ul.navbarmenu>li:hover ul.submenu {
  display: flex;
}

.calendlytext {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;
  align-items: center;
}

.calendlyttl {
  color: var(--Primary);
}


.calendy {
  width: 155px;
  height: 50px;
  background-color: white;
  color: #006BFF;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  border: 1px #006BFF solid;
  text-align: center;
  line-height: 36px;
}

.calendlysaprator {
  border-bottom: 1px var(--Black) solid;
  text-align: center;
  position: relative;
  margin: 32px 0;
}

.calendlysaprator span {
  z-index: 2;
  position: absolute;
  display: inline-block;
  background: var(--Primary);
  color: var(--White);
  height: 32px;
  width: 32px;
  line-height: 34px;
  top: -16px;
  left: 50%;
  margin-left: -16px;
  border-radius: 100%;
  font-size: 14px;
}

.calendlysaprator:after {
  content: "";
  z-index: 1;
  position: absolute;
  display: inline-block;
  background: var(--White);
  height: 32px;
  width: 64px;
  top: -16px;
  left: 50%;
  margin-left: -32px;
  font-size: 14px;
}

.contactselection p {
  text-align: left;
}

.context h4 {
  font-size: 20px;
  line-height: 30px;
}

.apipop {
  padding-top: 32px;
}

.riskmodes .protectedvalue {
  background: #d53e4f;
  border-radius: 16px;
  height: 16px;
  width: 16px;
}

.riskmodes .position_loss {
  background: #d1d1d1;
  border-radius: 16px;
  height: 16px;
  width: 16px;
}

.riskmodes .protectedvalue {
  background: #d53e4f;
  border-radius: 16px;
  height: 16px;
  width: 16px;
}

.riskmodes .position_gains {
  background: #ade8be;
  border-radius: 16px;
  height: 16px;
  width: 16px;
}

.error-message {
  color: red;
  font-size: 12px;
}

.subhighlight {
  font-size: 17px !important;
  line-height: 31px !important;
  color: var(--Secondary) !important;
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-weight: bold;
  justify-content: center;
}

.subhighlight:before,
.subhighlight:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 4px;
  border-radius: 2px;
  background: var(--Primary);
}

.productcard img {
  width: 80px;
  height: 80px;
}

.productcardsec {
  display: flex;
  column-gap: 64px;
}

.productcard {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.productcard h3 {
  font-size: 24px;
  color: var(--Black);
  line-height: 32px;
  text-transform: uppercase;
}

.productcard p {
  font-size: 18px;
  line-height: 28px;
}

.featureview {
  flex-direction: column;
  justify-content: center;
  row-gap: 48px;
  max-width: 100% !important;
}

.featureview h2 {
  text-align: center;
}

.sectiontitle {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.gap100 {
  row-gap: 100px !important;
}

.ShieldCard {
  background: var(--Bgsecondary);
  padding: 0;
}

.ShieldCard.whitesec {
  background: var(--White);
}

.innercontent {
  padding: 100px 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
  background-image: url(/..//app/Assets/comb_grid.svg);
  background-position: left center;
  background-size: auto 500px;
  background-repeat: no-repeat;
  max-width: 100% !important;
}

.innercontentleft {
  padding: 100px 200px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  background-image: url(/..//app/Assets/comb_grid_right.svg);
  background-position: right center;
  background-size: auto 500px;
  background-repeat: no-repeat;
  max-width: 100% !important;
}


.risktools {
  width: 100%;
  display: flex;
  position: relative;
  line-height: 1.4;
}

.risktools .faqcon {
  width: 60%;
  padding-right: 64px;
}

.risktools .fetureimg {
  width: 650px;
}

.imgaccod .content {
  color: var(--Primary);
}

.imgaccod .active {
  color: var(--Primary) !important;
}

.imgcon {
  position: absolute;
  right: 0;
  width: 40%;
  top: 0;
}

.imgcon img {
  width: 100%;
  height: auto;
}

.whitesec span,
.ShieldCard span {
  color: var(--Primary);
  font-weight: bold;
}

.ShieldCardhead {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.whitesec h2,
.ShieldCard h2 {
  font-size: 46px;
  margin: 0;
  font-weight: normal;
}

.whitesec p,
.ShieldCard p {
  margin: 0;
  font-weight: 300;
  font-size: 24px;
  align-items: center;
  text-align: justify;
}

.ShieldCardcon {
  width: 100% !important;
  display: flex;
  column-gap: 64px;
}
.ShieldCardcon.cardcol{
  flex-direction: column;
}
h2{
  font-size: 36px;
}
.textu{text-decoration: underline;}

.cardshield img {
  width: 100%;
  height: auto;
}

.sectiondividenopad {
  padding: 0 !important;
}

.commingsoon {
  padding: 4px 4px 2px;
  color: var(--Primary);
  font-weight: bold;
  font-size: 10px;
  background: var(--Bgprimary);
  margin-left: 8px;
}

.SecurityProtocol {
  background: var(--Bgprimary);
  width: 1220px;
  max-width: 100%;
  display: flex;
  padding: 48px;
  border-radius: 16px;
  column-gap: 64px;
  align-items: center;
}

.SecurityProtocol .sec-head {
  text-align: left;
}

.SecurityProtocol p {
  font-size: 20px;
  line-height: 32px;
}

.demoimg {
  width: 100%;
}

.demoimg img {
  width: 100%;
  height: auto;
}

.feturecardcon {
  display: flex;
  column-gap: 32px;
  width: 100%;
}

.FeatureCard {
  background: var(--Bgsecondary);
  width: 25%;
  padding: 32px;
  border-radius: 8px;
  text-decoration: none;
}

.FeatureCard.style2 {
  display: flex;
  width: 33.33%;
  flex-direction: row;
  column-gap: 32px;
  padding: 64px 32px;
  border-bottom: 5px var(--Primary) solid;
}

.FeatureCard.style2 h3 {
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
  color: var(--Black);
}

.FeatureCard h3 {
  font-size: 24px;
  line-height: 32px;
  margin-top: 24px;
  margin-bottom: 16px;
}

/* .FeatureCard:hover {
  background: var(--Primary);
}

.FeatureCard:hover h3,
.FeatureCard:hover p {
  color: var(--White);
}

.FeatureCard:hover img {
  filter: brightness(0) invert(1);
} */

.comparetable {
  margin-top: 32px;
}

.comparetable td {
  padding: 32px;
  text-align: center;
  color: var(--Black);
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px #D8D8D8 solid;
}

.comparetable td:first-child {
  text-align: left;
}

.comparetable tr:last-child td {
  border: none;
}

.comparetable thead tr:first-child td:nth-child(2) {
  border-radius: 16px 16px 0 0;
}

.comparetable tbody tr:last-child td:nth-child(2) {
  border-radius: 0 0 16px 16px;
}

.comparetable td:nth-child(2) {
  background: var(--Bgsecondary);
  border: none;
}

.comparetablecon {
  overflow: auto;
  /* max-width: 100%; */
}

.HeroSectionv2 {
  text-align: center;
  justify-content: center;
}

.HeroSectionv2 .context {
  max-width: 900px;
  text-align: center;
  align-items: center;
}
.HeroSectionv2 h4{
  color: var(--Black);
  text-align: center;
  font-size: 34px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -1px;
  margin-top: 24px;
}
.HeroSectionv2 h4 span{
  color: var(--Secondary);
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
}

/* Footer  */

.footer {
  background: var(--Primary);
  padding: 0;
}

.footer .sectiondivide {
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 128px;
  padding: 100px 200px;
  background-image: url('/..//app/Assets/line_bg_l.svg');
  background-position: center center;
  background-size: calc(100% - 200px);
  background-repeat: repeat-y;
  padding-top: 0;
  padding-bottom: 0;
}

.footertop {
  display: flex;
  width: 100%;
  padding: 32px 0;
  justify-content: space-between;
  align-items: center;
}

.footertop img {
  filter: brightness(0) invert(1);
}

.footerlink {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 32px 0;
}

.footerlink>div {
  width: 25%;
  max-width: 230px;
}

.footerlink h3 {
  color: var(--Secondary);
  text-align: left;
}

.footerlink ul {
  list-style: none;
  padding: 0;
}

.footerlink li {
  text-align: left;
}

.footerlink a {
  color: var(--White);
  text-decoration: none;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottomfoot {
  background: rgba(255, 255, 255, 0.1);
}

.footerbottom {
  color: var(--White);
  text-align: left;
  line-height: 28px;
}

.termlink {
  list-style: none;
  display: flex;
  padding: 0;
  column-gap: 24px;
}

.termlink li {
  float: left;
}

.termlink li a {
  color: var(--Secondary);
  text-decoration: none;
}

.socialicon {
  display: flex;
  column-gap: 16px;
}

.socialicon a {
  display: inline-block;
  padding: 10px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

.socialicon a img {
  float: left;
}

/* contact form */
.footersection .innercontent {
  width: 100%;
  align-items: center;
}

.contactfooter {
  padding: 48px;
  background: var(--White);
  max-width: 1000px;
  width: 100%;
  box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
}

.footersection .innercontent .input-mui-lg {
  margin-top: 0;
}

.footersection .innercontent form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footersection .innercontent .userform {
  width: 100%;
  align-items: center;
}

.footersection .innercontent .userform .doubleinput {
  width: 100%;
}

.footersection .innercontent .userform button {
  margin-top: 16px;
}

.footersection .innercontent textarea {
  min-height: 100px;
}

.factoranalysis {
  justify-content: flex-start;
}

.factorexposure {
  display: flex;
  width: 100%;
  padding: 24px 0;
  column-gap: 32px;
  row-gap: 32px;
  flex-wrap: wrap;
}

.factorexposure .contributio-table {
  width: calc(50% - 32px);
  padding: 0;
}

.subnavbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 48px;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  flex: 1;
}

.SUBITEM,
.SUBITEM-selected {
  display: block;
  text-align: center;
  color: #555;
  padding: 18px 0;
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  position: relative;
  white-space: nowrap;
}

.SUBITEM-selected {
  color: var(--Primary);
  border-bottom: 3px solid var(--Primary);
  border-radius: 0px;
}

.SUBITEM:hover {
  color: var(--Primary);
}

.SUBITEM-selected:after {
  background-color: var(--Primary);
}

.subnavbar-menu.navtab {
  padding: 16px;
}

.tabheader .subnavbar-menu {
  flex: none;
  float: left;
  height: auto;
}

.subnavbar-menu.fixed {
  height: 60px;
  column-gap: 32px;
}

.subnavbar-menu.fixed .SUBITEM-selected,
.subnavbar-menu.fixed .SUBITEM {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 992px) {
  .factorexposure .contributio-table {
    width: 100%;
  }
}

.factorexposure .contributio-table .table_holder {
  margin: 0;
  flex: 1;
}

.factorexposure .contributio-table .table_holder h3 {
  font-size: 16px;
}

.factorexposure .contributio-table .table_holder .table_height {
  flex: 1;
}

.factorexposure .portriskgrid {
  width: 50%;
  border-radius: 8px;
  overflow: hidden;
}

@media (max-width: 992px) {
  .factorexposure .portriskgrid {
    width: 100%;
  }
}

.factorexposure .portriskgrid h3 {
  background: var(--Bgsecondary);
  margin: 0;
  padding: 16px;
  color: var(--Primary);
  cursor: pointer;
  font-size: 18px;
}

.factorexposure .portriskgrid h3 span {
  float: right;
}

.factorexposure .portriskgrid .errorgrid {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  border: 1px var(--Bggrey) solid;
  column-gap: 32px;
  row-gap: 32px;
  justify-content: center;
}

.factorexposure .portriskgrid .errorgrid .trackingerror {
  text-align: center;
}

.factorexposure .portriskgrid .errorgrid .trackingerror h3 {
  background: none;
  margin-bottom: 8px;
}

.factorexposure .portriskgrid .errorgrid .trackingerror h4 {
  font-size: 14px;
  color: var(--Black);
  margin: 0;
  text-align: left;
  width: 120px;
}

.factorexposure .portriskgrid .errorgrid .trackingerror p {
  font-size: 22px;
  color: var(--Primary);
  font-weight: 600;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px var(--Bgprimary) solid;
}

.factorexposure .portriskgrid .errorgrid .trackingerror h5 {
  font-size: 14px;
  color: var(--Primary);
  margin-top: 8px;
}

.factorexposure .portriskgrid .errorgrid .trackingerror .tag {
  color: var(--White);
  padding: 4px;
  border-radius: 4px;
  display: inline-block;
  width: 60px;
  text-align: center;
  font-size: 14px;
}

.factorexposure .portriskgrid .errorgrid .trackingerror .tag.primary {
  background: var(--Primary);
}

.factorexposure .portriskgrid .errorgrid .trackingerror .tag.secondary {
  background: var(--Secondary);
}

.factorexposure .portriskgrid .errorgrid .trackingerror .tag.gray {
  background: var(--Grey);
}

.factorexposure .portriskgrid .errorgrid .trackingerror .errolist {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 16px;
}

.factorexposure .portriskgrid .errorgrid .trackingerror .errolist .tagspan {
  display: flex;
}

.nogap .table_holder {
  margin: 0;
}

.factoranalysis .factortbl {
  flex: none;
}

.factoranalysis .factortbl .subnavbar-menu {
  flex: none;
  max-height: 60px;
}

.factoranalysis .factortbl .table_holder {
  flex: none;
  height: auto;
}

.tabheader {
  background: var(--Bgprimary);
  margin: 0;
  padding: 0 16px;
  color: var(--Primary);
  cursor: pointer;
  font-size: 18px;
  float: left;
  width: 100%;
}

.tabheader .subnavbar-menu .SUBITEM,
.tabheader .subnavbar-menu .SUBITEM-selected {
  padding: 15px;
}

.tabheader span {
  float: right;
  padding: 16px 0;
}

.factortbl1 th:nth-child(2),
.factortbl1 td:nth-child(2),
.factortbl1 th:nth-child(3),
.factortbl1 td:nth-child(3),
.factortbl1 th:nth-child(4),
.factortbl1 td:nth-child(4) {
  text-align: right !important;
}

.subnavbar-menu {
  margin: 0;
  display: flex;
  column-gap: 32px;
  align-items: center;
  height: 100%;

}

.navtab.subnavbar-menu {
  position: absolute;
  bottom: 0;
  top: auto;
  height: 40px;
  left: 32px;
  z-index: 2;
}

.navtab.subnavbar-menu .SUBITEM,
.navtab.subnavbar-menu .SUBITEM-selected {
  padding: 8px 0px;
}

.SUBITEM,
.SUBITEM-selected {
  display: block;
  text-align: center;
  color: #555;
  padding: 18px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  position: relative;
}

.SUBITEM-selected {
  color: var(--Primary);
  border-bottom: 3px solid var(--Primary);
  border-radius: 0px;
}

.SUBITEM:hover {
  color: var(--Primary);
}

/* .SUBITEM-selected {
  background: var(--Secondary);
  color: var(--White);
} */

.SUBITEM-selected:after {
  background-color: var(--Primary);
}

@media (max-width: 480px) {
  .subnavbar {
    padding: 0;
    overflow: auto;
  }

  .subnavbar-menu {
    column-gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.last_updated_text {
  display: block;
  text-align: center;
  color: var(--Primary);
  padding: 8px 16px;
  font-family: "Inter";
  font-weight: 800;
  font-size: 15px;
  position: relative;
  flex-direction: column;
  margin: 0;
}

.content404 {
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content404 img {
  max-width: 100%;
  height: auto;
}

.usercardcontainer {
  display: flex;
  column-gap: 44px;
  flex-wrap: wrap;
}

.usercard {
  max-width: 100%;
  width: 300px;
}

.usercard .userinfo {
  display: flex;
  padding: 16px 0;
}

.designation {
  flex: 1;
  row-gap: 8px;
  display: flex;
  flex-direction: column;
}

.usercard .userinfo p {
  font-size: 16px;
  color: var(--Secondary);
}

.usercard .userinfo h3 {
  margin: 0;
}

.portfoliodata {
  margin: 0;
  width: 100%;
}

.tickrdata {
  margin: 0;
  width: 50%;
}

.tikerdatabtn {
  display: flex;
  padding: 16px 8px 0;
  justify-content: space-between;
  column-gap: 16px;
  row-gap: 16px;
}

.tikerdatabtn .right {
  display: flex;
  column-gap: 16px;
}

.importbtn {
  display: flex;            
  justify-content: center; 
  align-items: center;
  padding: 16px 8px 0;
  column-gap: 20px;
}

.haloor {
  min-width: 36px;
  position: relative;
}

.haloor span {
  position: absolute;
  margin-top: -18px;
  top: 50%;
  /* z-index: 2; */
  background: var(--Primary);
  color: var(--White);
  min-width: 36px;
  width: 36px;
  height: 36px;
  line-height: 38px;
  border-radius: 100%;
  display: block;
  text-align: center;
}

.risktable {
  align-items: center;
}

.halotblscroll {
  width: 100%;
  overflow: auto;
  max-height: 300px;
}

.upload-section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #E6E8F0;
  border-radius: 4px;
  position: relative;
  font-size: 20px;
  column-gap: 16px;
}

#file-upload {
  display: none;
}

.full-label {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  column-gap: 16px;
  padding: 8px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-error {
  font-size: 16px;
  color: red;
  margin-top: 4px;
}


.upload-section img {
  max-height: 100%;
}

.upload-section:hover {
  background-color: #f5f5f5;
}

.import-info {
  display: flex;
  column-gap: 16px;
}

.import-info img {
  margin-top: 10px;
}

.import-info text {
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.266px;
  color: #263154;
  font-size: 14px;
  /* padding-left: 10px; */
}

.import-info p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #263154;
}

.import-info div {
  justify-content: center;
  align-items: center;
  background: #E6E8F0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  flex: 1;
}

.ai-tabs.nav-tabs {
  list-style: none;
  margin: 0;
  border: none;
  justify-content: flex-start;
  padding: 0 32px;
  column-gap: 48px;
}

.ai-tabs li {
  display: inline-block;
  margin-right: 12px;
  position: relative;
}

.ai-tabs.nav-tabs .nav-item {
  position: relative;
  margin-right: 12px;
}

.ai-tabs.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.ai-tabs.nav-tabs .nav-item button,
.ai-tabs.nav-tabs .nav-item {
  appearance: none;
  background: transparent;
  border: none;
  color: var(--Grey);
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 8px 0;
  margin: 0;
  margin-bottom: 6px;
}

.ai-tabs.nav-tabs .nav-item {
  margin: 0 24px;
}

.ai-tabs.nav-tabs .nav-item button.active,
.ai-tabs.nav-tabs .nav-item.active {
  color: var(--Primary);
}

.ai-tabs li:last-child {
  margin-right: 0;
}

.ai-tabs.nav-tabs .nav-item button:after,
.ai-tabs.nav-tabs .nav-item:after {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 0;
  height: 3px;
  width: 100%;
  border-radius: 3px;
  background-color: none;
}

.ai-tabs.nav-tabs .nav-item button.active:after,
.ai-tabs.nav-tabs .nav-item.active:after {
  background-color: var(--Primary);
}
.navbarwrapper{
  display: flex;
  padding: 16px 24px;
  column-gap: 32px;
}
.bannerText {
  padding: 8px 24px;
  background: var(--Primary);
  overflow: hidden;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  text-align: center; 
  color: white; 
  font-weight: bold;
}

.is-sticky .navbarwrapper {
  display: flex;
  padding: 8px 24px !important;
}

.is-sticky .bannerText {
  height: 0 !important;
  padding: 0 24px;
}

.is-sticky .navbarwrapper{
  display: flex;
  padding: 8px 24px !important;;
}
.is-sticky .bannerText{
  height: 0 !important;
  padding: 0 24px;
}
/* .bannerText marquee{ float: left;width: 100%;} */

@media (max-width: 1680px) {
  .adaptive-help-left {
    padding-right: 0px;
    width: 50%;
  }

  .adaptive-help-right {
    width: 50%;
  }

  .adaptive-help-right img {
    width: 100%;
  }

  .adaptive-help {
    column-gap: 50px;
  }

  .innercontent,
  .innercontentleft,
  .sectiondivide,
  .footer .sectiondivide {
    padding: 100px;
    column-gap: 84px;
  }

  .ShieldCard {
    padding: 0;
  }
}

@media (max-width: 1500px) {
  ul.submenu {
    left: auto;
    right: 0;
  }
}

@media (max-width: 1440px) and (min-width: 993px) {

  .sectiondivide .context,
  .sectiondivide .image {
    max-width: 50%;
  }

}

@media (max-width: 1440px) {
  .ShieldCardcon {
    column-gap: 24px;
  }


  .ShieldCard {
    padding: 0;
  }

  .footer .sectiondivide .footerlink,
  .footer .sectiondivide .footertop {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .footerlink>div {
    width: 50%;
    max-width: 50%;
    text-align: left;
  }

  .footerlink>div ul {
    display: inline-block;
  }

  .footerlink .commingsoon {
    margin-left: 32px;
  }
}

@media (max-width: 1280px) {
  .apisection .apioutput {
    max-width: 100%;
    width: 100%;
    column-gap: 32px;
  }

  .apisection .apilist {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
  }

  .apisection .apilist {
    flex-direction: column;
  }

  .apisection {
    flex-wrap: wrap;
  }

  .apisection .apilist .tablist {
    width: auto;
  }

  .apisection .apidata {
    width: 100%;
    max-width: 100%;
  }

  .apisection .apioutput .code {
    width: 100%;
    max-width: 100%;
  }

  .ShieldCard {
    padding: 0;
  }
}

@media (max-width: 1160px) {
  .feturecardcon {
    flex-wrap: wrap;
    width: 100%;
    row-gap: 32px;
  }

  .FeatureCard.style2,
  .FeatureCard {
    width: calc(50% - 16px);
    box-sizing: border-box;
  }

  .footer .sectiondivide,
  .sectiondivide,
  .sectionhead {
    background-size: calc(100% - 128px);
  }

  .innercontent,
  .innercontentleft,
  .sectiondivide,
  .footer .sectiondivide {
    column-gap: 64px;
    padding: 64px;
  }
  .sectiondivide.tables.halocard{
    align-items: center;
  }
  .halocard .tickrdata{
    width: 100%;
  }

  .fieldbox {
    padding: 32px;
  }

  .adaptive-help {
    padding-top: 185px;
  }

  .contact-new {
    padding-top: 64px !important;
  }

  .line-container {
    padding: 0px 50px;
  }

  .ShieldCard {
    padding: 0;
  }
}

@media (max-width: 1140px) {
  .navbarmenu {
    column-gap: 24px;
    row-gap: 8px;
  }
  .navbarmenu a.button{
    height: 36px !important;
    padding: 0px 16px !important;
  }
}

@media (max-width: 992px) {
  .content404 {
    padding-top: 150px !important;
  }

  .catlist,
  .faqlist {
    width: 100%;
  }

  .forwardtest .forgraphs {
    width: 100%;
  }

  .forwardanalyze-portfolio {
    width: 100%;
  }

  .dataforwardtest {
    flex: auto;
  }

  .sectiondivide.demoscreen {
    flex-direction: column;
  }

  .article.context {
    padding-right: 0;
  }

  .imageleft {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .adaptive-help-left {
    width: 100%;
  }

  .adaptive-help-right {
    width: 100%;
    order: -1;
  }

  .adaptive-help {
    row-gap: 0px;
  }

  .protection {
    row-gap: 0px;
  }

  .protection-img {
    margin-top: 30px;
  }

  .protection-img img {
    width: 100%;
  }

  .innercontent,
  .innercontentleft,
  .sectiondivide {
    flex-direction: column;
    row-gap: 64px;
  }

  .article.context {
    padding-right: 0;
  }

  .section1 {
    padding-top: 128px;
    flex-direction: column-reverse;
  }

  .sectiondivide>div {
    max-width: 100%;
  }

  .upload-section,
  .portfoliodata {
    width: 100%;
  }

  .twocol>div {
    width: 100%;
  }

  .getalerts {
    flex-wrap: wrap;
  }

  .ShieldCard {
    padding: 0;
  }

  .risktools .faqcon {
    width: 100%;
    padding-right: 0;
  }

  .imgcon {
    position: static;
    width: 100%;
    padding: 16px 24px 16px;
  }
}

@media (max-width: 960px) {

  .whitesec h2,
  .ShieldCard h2 {
    font-size: 32px;
  }

  .ShieldCardcon {
    flex-wrap: wrap;
    row-gap: 24px;
    justify-content: center;
  }

  .cardshield {
    max-width: 300px;
  }

  .navwrap {
    position: fixed;
    background: var(--Peach);
    z-index: 99;
  }

  ul.submenu {
    position: static;
    background: none;
    margin-bottom: 16px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  ul.navbarmenu>li {
    /* text-align: center; */
  }

  .navopen ul.navbarmenu {
    display: flex;
  }

  .sectionhead {
    margin-top: 76px;
  }

  .navwrap {
    position: fixed;
    background: var(--Peach);
  }

  .navwrap {
    z-index: 100;
  }

  .navtoggle.navopen span:first-child {
    transform: rotate(45deg);
    margin-top: 10px;
  }

  .navtoggle.navopen span:nth-child(2) {
    display: none;
  }

  .navtoggle.navopen span:last-child {
    transform: rotate(-45deg);
    margin-top: -2px;
  }

  ul.navbarmenu {
    position: fixed;
    background: var(--Navy-Blue);
    top: 76px;
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 16px 48px;
    display: none;
    left: 0;
    z-index: 999;
  }

  a.navtoggle {
    display: block;
  }

  .navbarmenu a {
    font-size: 16px;
    color: var(--White);
    padding: 24px;
    display: inline-block;
  }

  .navopen ul.navbarmenu {
    display: flex;
  }

  .navwrap {
    padding: 16px 32px;
  }

  .nav-wrap {
    position: fixed;
    background: var(--Peach);
  }

  .nav-wrap {
    z-index: 99;
  }

  .navtoggle.nav-open span:first-child {
    transform: rotate(45deg);
    margin-top: 10px;
  }

  .navtoggle.nav-open span:nth-child(2) {
    display: none;
  }

  .navtoggle.nav-open span:last-child {
    transform: rotate(-45deg);
    margin-top: -2px;
  }

  ul.navbarmenu {
    position: fixed;
    background: var(--Navy-Blue);
    top: 76px;
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 16px 48px;
    display: none;
    left: 0;
    z-index: 999;
  }

  ul.navbarmenu>li {
    width: 100%;
    margin-top: 16px;
  }

  ul.navbarmenu>li a {
    padding: 8px 16px;
    width: 100%;
  }

  ul.navbarmenu>li a:hover,
  .navbarmenu a.active {
    border-bottom: none;
    color: var(--Primary);
    background: var(--Secondary);
  }

  ul.submenu {
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
  }


  a.navtoggle {
    display: block;
  }

  .navbarmenu a {
    font-size: 16px;
    color: var(--White);
    padding: 24px;
    display: inline-block;
  }

  .nav-open ul.navbarmenu {
    display: block;
    height: calc(100vh - 100px);
    overflow: auto;
  }

  .nav-wrap {
    padding: 16px 32px;
  }

  .productcard {
    width: 100%;
  }

  .productcardsec {
    flex-wrap: wrap;
    row-gap: 48px;
  }

  .ShieldCard {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .comparetable td {
    padding: 16px;
  }

  .comparetable td {
    font-size: 16px;
    vertical-align: middle;
  }

  .comparetable tbody td img {
    width: 16px;
  }

  .innercontent,
  .innercontentleft,
  .sectiondivide {
    row-gap: 48px;
  }

  .footerlink>div {
    width: 100%;
    max-width: 100%;
  }

  .signupalert {
    padding: 24px;
  }

  .ProjectionApp {
    padding-left: 0px;
  }

  .porfoliovaluesline,
  .porfoliovalues {
    width: 62px;
  }

  .porfoliovalues div {
    font-size: 12px;
  }

  .porfoliovaluesline:after {
    width: calc(100% - 100px);
  }

  .porfoliovaluesline:before {
    width: calc(100% - 100px);
  }

  .port_val {
    display: none;
  }

  .port_date {
    width: calc(100% - 90px);
  }

  .port_date span {
    font-size: 12px;
    font-weight: bold;
  }

  .signupalert .reminder {
    flex-direction: column;
  }

  .vixdail {
    width: 300px;
  }

  .vixdail svg {
    margin-left: -30px;
  }

  .riskalert .signupform {
    flex-wrap: wrap;
    width: 100%;
  }

  .sec-head {
    font-size: 24px;
    line-height: 30px;
  }

  .weatherstat {
    flex-direction: column;
    padding: 0 32px;
  }

  .weatherstat.part2 {
    padding: 0;
  }

  .sectiondivide.demoscreen {
    padding: 100px 32px;
  }

  .doubleinput {
    flex-direction: column;
    row-gap: 16px;
  }

  .context h1,
  .context h2,
  .context h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .fieldbox {
    padding: 16px;
  }

  .fieldsubbox {
    flex-direction: column;
    row-gap: 16px;
  }

  .fieldsubbox h4 {
    line-height: 28px;
  }

  .adaptive-help-left h2 {
    margin: 0;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 15px;
  }

  .adaptive-help-left p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .adaptive-help-left .main-header.signupform {
    margin-top: 30px;
  }

  .protection h2 {
    margin: 0;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 15px;
  }

  .protection p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .welcome h2 {
    margin: 0px;
    font-size: 18px;
    line-height: 28px;
  }

  .line-container {
    padding: 0px 30px;
  }

  .adaptive-help {
    padding-top: 130px;
  }

  .taxsmartdiv,
  .divlast {
    flex-direction: column;
    row-gap: 32px;
  }

  .faqcon .faq h4 {
    font-size: 16px;
    line-height: 22px;
  }

  .faqcon .faq {
    padding: 0;
  }

  .innercontent,
  .innercontentleft,
  .sectiondivide,
  .footer .sectiondivide,
  .sectiondivide {
    column-gap: 48px;
    padding: 64px 32px;
  }

  .section1 {
    padding-top: 128px;
  }

  .context h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .context h2,
  .context h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .field-box {
    padding: 16px;
  }

  .field-subbox {
    flex-direction: column;
    row-gap: 16px;
  }

  .field-subbox h4 {
    line-height: 28px;
  }

  .taxsmartdiv>div {
    width: 100%;
  }

  .ShieldCard {
    padding: 0;
  }

  .SecurityProtocol {
    flex-direction: column;
  }
}

@media (max-width: 550px) {

  .FeatureCard.style2,
  .FeatureCard {
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 500px) {
  .contactfooter {
    padding: 24px;
  }

  .termlink {
    flex-direction: column;
  }

  .footertop {
    row-gap: 24px;
    padding-bottom: 0;
  }

  .footerlink {
    margin-top: 0;
  }

  .apisection .apioutput {
    flex-direction: column;
  }

  .ShieldCard {
    padding: 0;
  }
}

@media (max-width: 480px) {

  .footer .sectiondivide,
  .sectiondivide {
    background-size: calc(100% - 64px);
  }

  .innercontent,
  .innercontentleft,
  .sectiondivide,
  .footer .sectiondivide {
    column-gap: 32px;
    padding: 32px;
  }

  .riskhead .riskstate {
    display: flex;
    flex-direction: row;
    width: 100%;
    column-gap: 16px;
    align-items: center;
    margin-bottom: 16px;
  }

  .riskhead h2 {
    width: 100%;
    flex-wrap: wrap;
    flex: auto;
    margin: 0;
  }

  .riskmodes {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
  }

  .section1 {
    padding-top: 128px;
  }

  .riskalert {
    min-width: 0px;
    width: 100%;
  }

  body .popuptext {
    padding: 0 8px;
  }

  .innercontent,
  .innercontentleft,
  .sectiondivide {
    padding: 48px 24px;
  }

  .section1 {
    padding-top: 128px;
  }

  .inpcon,
  .signupform {
    flex-direction: column;
  }

  .getalerts .input-type-text-area-sm {
    min-width: 0px;
    width: 100%;
  }

  .inputs>div {
    width: 100%;
  }

  .inputs>div.big {
    width: 100%;
  }

  .getalerts .input-type-text-area-sm {
    min-width: 0px;
    width: 100%;
  }

  .dialcenter {
    transform: scale(0.8);
  }

  .riskhead {
    margin-top: 32px;
  }

  .ShieldCard {
    padding: 0;
  }


}

@media (max-width: 400px) {
  #app {
    transform: scale(0.7);
    margin: -40px;
  }

  .FeatureCard.style2 {
    flex-direction: column;
    ROW-gap: 32PX;
    padding: 24PX;
  }

  .ShieldCard {
    padding: 0;
  }

  ul.navbarmenu>li.navbutton {
    flex-direction: column;
    row-gap: 16px;
  }
}

.popupselecttext {
  row-gap: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--Primary);
}

.popupselecttext h3{
  margin: 0;
  text-align: center;
}

.popupinfo{
  margin: 0;
  text-align: center;
  color: var(--Black);
}
.halopopup{
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
  padding: 0 16px;
}
.userselector{
  display: flex;
  column-gap: 24px;
  width: 100%;
}
.userselector a{
  background: #f5f5f5;
  padding: 16px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  border-radius: 4px;
  color: var(--Black);  
}
.userselector a:hover{
  background: var(--Primary);
  color: var(--White);
}
.userselector a:hover img {
  filter: invert(1) brightness(1);
}
.halonotice{
  background: var(--Bgprimary);
  color: var(--Primary);
  border: 2px var(--Primary) dashed;
  padding: 24px;
  border-radius: 8px;
  margin: 24px 0;  
  text-align: center;
  word-break: break-word;
}
.halonotice a{
  font-weight: bold;
  color: var(--Primary);
}

.blank-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #FFFFFF;
  z-index: 10000;
}

.input-container {
  position: relative;
  display: flex;
  flex-direction: column; 
  align-items: start; 
  width: fit-content; 
}

.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin-top: 5px;  /* Spacing between the tooltip and the input */
  box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
  font-size: 12px;
  border-radius: 4px;

  /* The ::before pseudo-element adds the triangular arrow pointing up */
  ::before {
      content: '';
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #ccc;
      position: absolute;
      top: -5px;
      left: 10px;  /* Adjust based on your content */
  }

  /* The ::after pseudo-element adds the inner triangular arrow to cover the gap */
  ::after {
      content: '';
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid #fff;
      position: absolute;
      top: -4px;
      left: 10px;  /* Adjust based on your content */
  }
}
.innercontent.full{
  width: 100%;
}
.priceblock{
  display: flex;
  gap: 64px;
  justify-content: center;
  width: 100%;
}
.cardprice{
 max-width: 500px;   
 padding: 32px;
 display: flex;
 flex-direction: column;
 align-content: flex-start;
}
.cardprice h3{
  font-size: 32px;
  margin: 0;
}
.cardprice ul{list-style: none;padding: 0;margin:16px 0;flex: 1;}
.cardprice ul li{padding: 8px 0;font-size: 24px;}
.cardprice ul li p{font-size:14px; color: var(--Black);}
.cardprice h3{
  display: flex;
  align-items: center;
  gap: 24px;
}
.cardprice h3::after{
  content: "";
  display: flex;
  flex: 1;
  height: 4px;
  transform: skewX(45deg);
}
.cardprice.investor h3{
  color: var(--Secondary);
}
.cardprice.advisor h3{
  color: var(--Primary);
}
.cardprice ul li.price div{
  display: inline-block;
  /* border-bottom: 5px rgba(0, 0, 0, 0.1) solid; */
}
.cardprice ul li.price span{
  color: var(--Black);
  font-size: 16px;
  margin-left: 8px;
}
.cardprice.investor ul li.price{
  color: var(--Secondary);
  font-style: italic;
  font-weight: 500;
}
.cardprice.advisor ul li.price{
  color: var(--Primary);
  font-style: italic;
  font-weight: 500;
}
.cardprice.investor h3::after{
  background: var(--Secondary);
}
.cardprice.advisor h3::after{
  background: var(--Primary);
}
.cardprice a{
  font-size: 16px;
  padding: 8px 24px;
}
.bhighlight div{
  display: inline-block;
  font-size: 20px;
  /* border-bottom: 5px rgba(0, 0, 0, 0.1) solid; */
}
.bhighlight div a{
  color: var(--Black);
}
.MuiMenu-list{
  max-height: 400px !important;
  overflow: auto !important;
}
.ovv{
  overflow: visible !important;
}
body .react-datepicker-popper .react-datepicker__triangle::before,body .react-datepicker-popper .react-datepicker__triangle::after{
  display: none !important;
}
body .react-datepicker__current-month,body .react-datepicker-time__header,body .react-datepicker-year-header{
  padding: 16px;
  color: var(--Secondary);
}
body .duration button{
  border: none;
  top: 14px;
}
body .react-datepicker-popper{
  padding: 0 !important;
}
body .react-datepicker{
  font-family: "Overpass", sans-serif;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
  background: var(--Primary) !important;
}
.chatcontainer{
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 8px 8px 0 0;
  position: fixed;
  width: 500px;
  height: 600px;
  bottom: 0;
  right: 50px;
  overflow: hidden;
  background: #fff;
  max-width: 100%;
}
.chatcontainer iframe{
  margin:-110px -2px 0;
  width: calc(100% + 4px);
  height:calc(100% + 145px);
}
.chatcontainer .botheader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding:8px 16px;
  background: var(--Peach);
  font-weight: 500;
  color: var(--Primary);
}
.ally{
  position: fixed;
  width: 60px;
  height: 60px;
  right: 50px;
  bottom: 80px;
}
.ally img{
  width: 100%;
}
.botheader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.botheader a{
  padding: 8px;
  color: red;
  display: flex;
}
@media (max-width: 992px) {
  .chatcontainer{
    right: 2px;
  } 
}

/*  */

.hero-sectionpricing .context {
  max-width: 650px;
}

.price-sheildCard {
  background-color: #ffffff;
  justify-content: center;
}

.price-sheildCard .innercontent {
  padding: 0;
}

.price-sheildCard .innercontent .context {
  text-align: center;
  width: 100%;
}

.pricecardshield {
  width: 33%;
  box-shadow: 0px 20px 40px 0px #7090B01A;
  border-radius: 20px;
  padding: 32px 32px;
  border: 10px solid transparent;
  transition: all .48s ease-in-out;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #ffffff;
}
.pricecardshield.popular{
  margin: -50px 0;
  border: 10px solid #FCEAEB;
  z-index: 2;
}
.pricecardshield:hover {
  border: 10px solid #FCEAEB;
  transform: scale(1.03);

}

.pricing-card {
  column-gap: 0;
  row-gap: 24px;
}

.in-icon {
  width: 64px;
  height: 64px;
  background: var(--Bgsecondary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-content-investor p {
  line-height: 26px;
  color: #242424;
}

.price-listing {
  margin-top: 24px;
}

.top-heading-in,
.price-top {
  display: flex;
  gap: 16px;
  align-items: center;
}

.top-heading-in h3 {
  color: var(--Primary);
  text-transform: uppercase;
}

.price-number {
  margin: 0;
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
}

.web-tag {
  padding: 2px 12px;
  font-size: 14px;
  color: var(--Primary);
  font-weight: 500;
  background-color: var(--Bgsecondary);
  border-radius: 8px;
  height: 28px;
  display: flex;
  align-items: center;
}

.price-btm p {
  color: var(--Grey);
}

.card-btn {
  width: 100%;
  margin-top: auto;
}

.highlight-text {
  text-align: center;
  color: var(--Grey);
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-transform: capitalize;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.line-right,
.line-left,
.highlight-text p {
  width: 33%;
}

.line-left,
.line-right {
  border: 1px solid #BABAC4
}

.marking-list ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 0;
  flex: 1;
}

.marking-list ul li {
  padding: 8px 16px 8px 36px;
  color: var(--Black);
  position: relative;
  list-style: none;
  line-height: 26px;
}
.marking-list li.close:before {
  background: url(/..//app/Assets/close.svg);
  background-size: 14px;
  background-position:center;
  background-repeat: no-repeat;
}
.marking-list li:before {
  content: "";
  width: 14px;
  height: 20px;
  background: url(/..//app/Assets/tick.svg);
  position: absolute;
  top: 10px;
  left: 12px;
  background-position: center;
  background-size: contain;
  background-repeat:no-repeat ;
}

.marking-list li:nth-of-type(odd) {
  background: linear-gradient(97.16deg, rgba(229, 139, 118, 0.1) 0%, rgba(229, 139, 118, 0.1) 100%);
  border-radius: 4px;
}

.pricecardshield:hover .marking-list {
  align-self: flex-start;
  width: 100%;
}

.in-icon.p {
  background: #EFE6ED;
}

.popular-tag {
  padding: 8px 16px;
  background: #ED6663;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0px;
  border-bottom-left-radius: 16px;
  font-size: 15px;
  border-top-right-radius: 16px;
  line-height: 25px;
}

.card-content-investor {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.in-icon.pro {
  background: linear-gradient(180deg, rgba(229, 139, 118, 0.05) 0%, rgba(96, 3, 76, 0.05) 100%);
}

.cardshield-content {
  display: flex;
}

.cardshield-content p {
  flex: 1;
  min-height: 135px;
}
.priceintercontent{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.priceintercontent .context{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-bottom: 100px;
  width: 560px;
  max-width: 100%;
}
.priceintercontent .context ul{
  padding: 0;
}
.priceintercontent .context ul li{
  color: var(--Black);
  display: flex;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  align-content: center;
  gap: 24px;
}
@media(max-width:1024px){
  .ShieldCardcon{
    flex-direction: column;
    column-gap: 0;
    width: 100%;
    row-gap: 32px;
  }
  .pricecardshield{
    width: 100%;
  }
  .pricecardshield.popular{
    margin: 0;
  }
}

.custom-slider-container {
  position: relative;
  width: 100%; 
  margin: 40px 0;
}


.custom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  margin: 0;         
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--Primary);
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid var(--Primary);
  /* Optionally center the thumb vertically */
  margin-top: -5px;
}

/* Slider thumb for Firefox */
.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--Primary);
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid var(--Primary);
}

/* The movable tooltip above the slider */
.movable-tooltip {
  position: absolute;
  top: -40px;           /* Position above the slider track */
  left: 50%;            /* Default centered; inline style (left property) will override */
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  transition: left 0.3s ease; /* Smoother transition */
}

/* Tooltip arrow (a downward pointing triangle) */
.movable-tooltip:before {
  content: "";
  position: absolute;
  bottom: -5px; /* Position the arrow so it touches the slider */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/* Content inside the tooltip */
.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tick {
  position: absolute;
  bottom: -20px;  
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.asset-allocation-ticks {
  position: absolute;
  /* top: -20px; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 20px;
  white-space: nowrap;
}


.sumstates{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.sumstates .sumvalue{
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border: 1px solid #D9D9D9;
}
.sumstates label{
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.sumstates div{
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
/* 
.tooltip-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tick {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
} */